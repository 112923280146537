import { Link, withIntl } from '../../i18n'
import { StaticQuery, graphql } from 'gatsby'
import {
  bannerIcon,
  downloadButton,
  feature,
  fluidImage,
  phone,
} from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import Centered from '../../components/reusables/centered'
import Features from '../../components/reusables/features'
import { FormattedMessage } from 'react-intl'
import Hero from '../../components/reusables/hero'
import ImageText from '../../components/reusables/imageText'
import ImageTextHero from '../../components/reusables/imageTextHero'
import IndexMenu from '../../components/reusables/indexMenu'
import Layout from '../../components/layout'
import React from 'react'
import Rows from '../../components/reusables/rows'

const CmtCube = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <ImageTextHero index={0} data={data} shade={'light'} />
    <Features index={0} data={data} shade={'grey'} />
    <Centered index={0} data={data} shade={'light'} />
  </Layout>
)

export default withIntl(CmtCube)

export const imagesQuery = graphql`
  query {
    bannerCoin: file(relativePath: { eq: "hero/bannerCoin@3x.png" }) {
      ...bannerIcon
    }
    cmtCube: file(relativePath: { eq: "regular/cmtCube@3x.png" }) {
      ...phone
    }
    featuresCube: file(relativePath: { eq: "features/featuresCube@3x.png" }) {
      ...feature
    }
    featuresRise: file(relativePath: { eq: "features/featuresRise@3x.png" }) {
      ...feature
    }
    featuresDB: file(relativePath: { eq: "features/featuresDB@3x.png" }) {
      ...feature
    }
    featuresBattery: file(relativePath: { eq: "features/featuresBattery@3x.png" }) {
      ...feature
    }
    shortLastSection: file(relativePath: { eq: "hero/shortLastSection@2x.png" }) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
